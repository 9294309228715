import { Link, useStaticQuery, graphql } from 'gatsby';
import React, { useState } from 'react';
import ListItem from './list-item';
import AuthNav from './auth-nav';

const query = graphql`
  query GetGlobalSettings {
    globalSettings: contentfulGlobalSiteSettings {
      navigationItems {
        title
        to
        isCTA
        external
        disabled
      }
    }
  }
`;

function Header({ showNavigation }) {
  const [isExpanded, toggleExpansion] = useState(false);
  const { globalSettings } = useStaticQuery(query);
  return (
    <div className="flex flex-wrap items-center justify-between max-w-full mx-auto p-4 md:p-8 font-sans">
      <Link to="/" className="no-underline w-20">
        <img src="/logos/logo-w.svg" alt="logo" />
      </Link>
      {(showNavigation && (
        <>
          <button
            type="button"
            className="navigation-toggle"
            onClick={() => toggleExpansion(!isExpanded)}
          >
            <svg
              className="fill-current h-6 w-6"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
            &nbsp;Menu
          </button>

          <nav className={`${isExpanded ? 'block' : 'hidden'} navigation`}>
            <ul>
              {globalSettings.navigationItems
                .filter(i => !i.disabled)
                .map(i => (
                  <ListItem key={i.title} item={i} />
                ))}
              <AuthNav />
            </ul>
          </nav>
        </>
      )) ||
        null}
    </div>
  );
}

export default Header;
