/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

import ListItem from './list-item';
import LoadingAvatar from '../loading-avatar';
import CogIcon from '../../../static/assets/icons/icon-cog.svg';
import DoorExitIcon from '../../../static/assets/icons/icon-door-exit.svg';
import CheveronDownIcon from '../../../static/assets/icons/icon-cheveron-down.svg';
import ProfilePhotoPlaceholder from './profile-photo';
import GoToDashboard from './goto-dashboard';

const CURRENT_USER_QUERY = gql`
  query CurrentUser($profileType: String) {
    currentUser {
      id
      email
      confirmedAt
      defaultProfile
      profileDetails(profileType: $profileType) {
        name
        profilePicture
      }
      profiles {
        type
        name
        profilePicture
      }
    }
  }
`;

const LOGOUT_MUTATION = gql`
  mutation Logout {
    logout
  }
`;

const AccountSettings = (currentUser) => {
  const [isAccountMenuExpanded, setAccountMenuExpanded] = useState(false);
  const {profilePicture, profileName} = currentUser;
  const [logoutUser] = useMutation(LOGOUT_MUTATION);
  return (
    <li id="accountMenu" className="relative block md:inline-block mt-4 md:mt-0 align-middle">
      <a
        role="button"
        onClick={() => setAccountMenuExpanded(!isAccountMenuExpanded)}
        onKeyUp={e => {
          if (![13, 32].includes(e.keyCode)) {
            return;
          }

          setAccountMenuExpanded(!isAccountMenuExpanded);
        }}
        tabIndex={0}
        className="inline-block outline-none no-underline text-black"
      >
        {profilePicture ? (
          <img className="inline-block w-12 h-12 rounded-full" src={profilePicture} alt="profile" />
        ) : (
          <ProfilePhotoPlaceholder value={profileName} />
        )}{' '}
        {profileName}
        <CheveronDownIcon className="w-8 inline-block" />
      </a>
      <ul
        className={`${
          isAccountMenuExpanded ? 'block' : 'hidden'
        } whitespace-nowrap md:absolute right-0 shadow-md py-2 px-4 text-left leading-normal bg-white rounded-default text-base`}
      >
        <li className="block p-2 w-full text-white bg-blue-900 rounded">
          <a
            className="text-white no-underline"
            href={`${process.env.GATSBY_APP_ROOT}/dashboard`}
            rel="noopener noreferrer"
          >
            Open dashboard
          </a>
        </li>
        <li className="block w-full py-2 text-base border-b border-grey">
          <CogIcon className="w-5 inline-block align-middle" />
          <a
            href={`${process.env.GATSBY_APP_ROOT}/settings`}
            className="inline-block ml-1 text-black font-normal no-underline align-middle"
          >
            Settings
          </a>
        </li>
        <li className="block py-2 w-full text-black font-normal text-base border-t border-grey">
          <DoorExitIcon className="w-5 inline-block align-middle" />
          <button
            type="button"
            onClick={async () => {
              logoutUser();
              setAccountMenuExpanded(!isAccountMenuExpanded);
            }}
          >
            Sign out
          </button>
        </li>
      </ul>
    </li>
  );
}

const NonAuthenticated = () => (
  <>
    <ListItem
      key="login"
      item={{ external: true, to: `${process.env.GATSBY_APP_ROOT}/login`, title: 'Log In' }}
    />
    <ListItem
      key="signup"
      className="cta"
      item={{
        external: true,
        to: `${process.env.GATSBY_APP_ROOT}/signup`,
        isCTA: true,
        title: 'Try it FREE',
      }}
    />
  </>
);

const AuthNav = () => {
  const { loading, error, data: currentUserActionResult } = useQuery(CURRENT_USER_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  if (loading || error || !currentUserActionResult || !currentUserActionResult.currentUser) return <NonAuthenticated />;

  return <GoToDashboard />

};

export default AuthNav;
