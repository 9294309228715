import React from 'react';

const Footer = () => (
  <footer className="p-8">
    <div className="text-center max-w-6xl mx-auto mb-2">
      <p className="mb-4 text-xl">
        Need help? Mention{' '}
        <a
          className="underline text-gray-900 hover:text-theme-purple"
          href="https://twitter.com/weremoteeu"
          target="__blank"
          rel="noopener noreferrer nofollow"
        >
          @weremoteeu
        </a>{' '}
        on Twitter or email{' '}
        <a
          className="underline text-gray-900 hover:text-theme-purple"
          href="mailto:support@weremote.eu"
          rel="noopener noreferrer nofollow"
        >
          support@weremote.eu
        </a>
        .
      </p>

      <p>
        Designed and built out of{' '}
        <span role="img" aria-label="red heart">
          ❤️
        </span>{' '}
        for remote work by Adrian Oprea (
        <a
          className="underline text-gray-900 hover:text-theme-purple"
          href="https://twitter.com/oprearocks"
          target="__blank"
          rel="noopener noreferrer nofollow"
        >
          Twitter
        </a>
        ,{' '}
        <a
          className="underline text-gray-900 hover:text-theme-purple"
          href="https://linkedin.com/in/oprearocks"
          target="__blank"
          rel="noopener noreferrer nofollow"
        >
          LinkedIn
        </a>
        ).
      </p>
      <p>Copyright &copy; {new Date().getFullYear()} WeRemote Europe SRL. All Rights Reserved.</p>
    </div>
  </footer>
);

export default Footer;
