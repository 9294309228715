import React from 'react';
import { Link } from 'gatsby';
import clsx from 'clsx';

const ListItem = ({ item, className }) => (
  <li className="block md:inline-block mt-4 md:mt-0 align-middle">
    {item.external ? (
      <a
        href={item.to}
        rel="noopener noreferrer nofollow"
        className={clsx(className || 'nav-item')}
      >
        {item.title}
      </a>
    ) : (
      <Link
        to={item.to}
        className={clsx(className || 'nav-item')}
        activeClassName="nav-item--active"
      >
        {item.title}
      </Link>
    )}
  </li>
);

export default ListItem;
