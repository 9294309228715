import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

const detailsQuery = graphql`
  query DefaultSEOQuery {
    globalSettings: contentfulGlobalSiteSettings {
      title
      description {
        description
      }
      author
      keywords
    }
  }
`;

interface IProps {
  image?: string,
  description?: string,
  lang?: string,
  meta?: any[],
  keywords?: string,
  title?: string,
  robots?: string,
}

const unique = (element: any, index: number, items: any[]) => items.indexOf(element) === index;
function SEO(props: IProps) {
  const { image, description, lang, meta, keywords, title, robots } = props;
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const { globalSettings } = data;
        const metaDescription = description || globalSettings.description.description;
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title || globalSettings.title}
            titleTemplate={`%s | ${globalSettings.title}`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:image`,
                content: image || `https://${process.env.WEBSITE_DOMAIN}/logos/logo-w.svg`,
              },
              {
                name: `twitter:image`,
                content: image || `https://${process.env.WEBSITE_DOMAIN}/logos/logo-w.svg`,
              },
              {
                name: `twitter:card`,
                content: `summary_large_image`,
              },
              {
                name: `twitter:creator`,
                content: globalSettings.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
            ]
              .concat({
                name: `keywords`,
                content: globalSettings.keywords
                  .concat(keywords || [])
                  .filter(unique)
                  .join(','),
              })
              .concat(
                robots
                  ? {
                      name: `robots`,
                      content: robots,
                    }
                  : []
              )
              .concat(meta)}
          />
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string.isRequired,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SEO;
