/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import LoginIcon from '../../../static/assets/feather-icons/log-in.svg';

const CURRENT_USER_QUERY = gql`
  query CurrentUser {
    currentUser {
      id
    }
  }
`;

const GoToDashboard = () => {
  const { data } = useQuery(CURRENT_USER_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  if (!data || !data.currentUser) return null;

  return (
    <li id="accountMenu" className="md:ml-6 block md:inline-block mt-4 md:mt-0 align-middle">
      <a
        rel="noopener noreferrer"
        href={process.env.GATSBY_APP_ROOT}
        className="nav-item"
      >
        Go to dashboard
        <LoginIcon className="w-5 h-5 inline-block align-middle" />
      </a>
    </li>
  );
};

export default GoToDashboard;
